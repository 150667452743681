import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR,
    NgControl,
    Validators,
} from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MultilangInputModel } from './multilang-input.model';
import { Subject } from 'rxjs';
import { notNullOrUndefined } from '@libs/functions';
import { Language } from '@api/models/Postgres/Model/language';


/**
 * @Depricated
 * Multilang input
 */
@Component({
    selector: 'ui-multilang-input',
    templateUrl: './multilang-input.component.html',
    styleUrls: ['./multilang-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultilangInputComponent),
            multi: true,
        },
    ],
})
export class MultilangInputComponent
    implements OnChanges, OnDestroy, AfterViewInit {
    /**
     * Custom css class
     */
    @Input() customClass: string;
    /**
     * Required flag
     */
    @Input() required: boolean;
    /**
     * Label text
     */
    @Input() label: string;
    /**
     * Disable flag
     */
    @Input() disabledControl: boolean;
    /**
     * Data for fill
     */
    @Input() patch: MultilangInputModel[];
    /**
     * Selected language code
     */
    selectedLang: string;
    /**
     * Form control
     */
    control: FormControl = null;
    /**
     * Changed flag
     */
    changed: boolean;
    /**
     * Form object
     */
    form: FormGroup = this.fb.group({}, {});
    /**
     * List languages  (Auxiliary)
     */
    languagesValue: Language[];
    /**
     * Destroy var for unsubscribe
     */
    private readonly destroy$: Subject<unknown> = new Subject<unknown>();

    constructor(
        private fb: FormBuilder,
        private injector: Injector,
        private cd: ChangeDetectorRef,
    ) {
    }

    /**
     * List languages
     */
    @Input() set languages(languages: Language[]) {
        if (languages) {
            this.selectedLang = languages[0].code;
            this.makeForm(languages);
            this.languagesValue = languages;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.patch && this.languagesValue) {
            this.patch.forEach((val: MultilangInputModel) =>
                this.form.patchValue(
                    {
                        [val.lang]: val.value,
                    },
                    {
                        emitEvent: false,
                    },
                ),
            );
        }
    }

    ngAfterViewInit(): void {
        const ngControl: NgControl = this.injector.get<any>(NgControl as any, null);
        if (ngControl) {
            this.control = ngControl.control as FormControl;
            this.control.statusChanges
                .pipe(
                    distinctUntilChanged((a, b) => a === b),
                    takeUntil(this.destroy$),
                )
                .subscribe((status) => {
                    this.cd.markForCheck();
                });
        } else {
            console.warn('Input is not defined');
        }
    }

    propagateChange = (_: string) => {
    };
    touchedChange = (_: string) => {
    };

    patchValue(value: string): void {
        setTimeout(() => {
            this.cd.markForCheck();
        }, 0);
    }

    // @ts-ignore
    onChange(value) {
        this.change(value);
    }

    // @ts-ignore
    onBlur(value) {
        this.changed = true;
        this.touchedChange('');
    }

    // @ts-ignore
    onKeyUp(value) {
        this.change(value.target.value);
    }

    // @ts-ignore
    change(value) {
        this.cd.markForCheck();
        this.propagateChange(value);
    }

    writeValue(value: string): void {
        this.patchValue(value);
    }

    // @ts-ignore
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    // @ts-ignore
    registerOnTouched(fn: any): void {
        this.touchedChange = fn;
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    private makeForm(languages: Language[]): void {
        const controls: { [key: string]: AbstractControl } = {};
        languages.map((lang: Language) => {
            controls[lang.code] = this.fb.control(null, [Validators.required]);
        });
        this.form = this.fb.group(controls, {});
        this.form.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((values) => {
                const prepareValues: MultilangInputModel[] = [];
                for (const key in values) {
                    if (values.hasOwnProperty(key)) {
                        if (notNullOrUndefined(values[key]) && values[key] !== '') {
                            prepareValues.push({
                                lang: key.toLowerCase(),
                                value: values[key],
                            });
                        }
                    }
                }
                this.change(prepareValues);
            });
    }
}
