import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { MenuItemsModel } from '@libs/menu/menu.model';
import { ModuleAccessService } from '@services/module-access/module-access.service';
import { Priv } from '@api/models/UserService/Contract/priv';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-submenu',
    templateUrl: './submenu.component.html',
    styleUrls: ['./submenu.component.scss'],
})
/**
 * Third level menu
 */
export class SubmenuComponent implements OnChanges {
    /**
     * List menu items
     */
    @Input() public menuItems: MenuItemsModel[];
    /**
     * List of system user privilege
     */
    @Input() public privs: Priv[];
    /**
     * List menu items (display)
     */
    public displayMenuItems: MenuItemsModel[];

    public ngOnChanges(): void {
        if (this.menuItems && this.privs) {
            this.displayMenuItems = this.menuItems.filter((item: MenuItemsModel) =>
                ModuleAccessService.checkMenuItemAccess(item, this.privs),
            );
        }
    }
}
