import { ChangeDetectionStrategy, Component, ElementRef, inject, Inject, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { DatabaseTimeModel } from '@libs/settings';
import { LocalDateAdapter } from '@services/local-date-adapter/local-date-adapter';


/**
 * Clock in navbar
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-clock',
    templateUrl: './clock.component.html',
    styleUrls: ['./clock.component.scss'],
    standalone: true,
})
export class ClockComponent {
    readonly #dateAdapter: LocalDateAdapter = inject(LocalDateAdapter);
    /**
     * Ref object
     */
    @ViewChild('clock', { static: true }) clock: ElementRef;

    public readonly format: string = this.#dateAdapter.format.time;

    private minIncrement: number = 0;
    private intervalId: number;

    constructor(
        @Inject('window') private window: Window,
    ) {
    }

    @Input()
    public set time(time: DatabaseTimeModel) {
        if (time) {
            this.showTime(time);

            if (this.intervalId) {
                this.window.clearInterval(this.intervalId);
            }

            this.intervalId = this.window.setInterval(() => {
                this.showTime(time);
            }, 60000);
        }
    }

    private showTime(time: DatabaseTimeModel): void {
        this.clock.nativeElement.innerHTML = time?.localtime
            ? moment(time?.localtime)
                .add(this.minIncrement, 'minute')
                .format(this.format)
            : '';

        this.minIncrement++;
    }
}
