// @ts-nocheck
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SortDirection } from '@ui/sort/model';
import { OrganizationModel } from '@libs/organization/organization.model';
import { TariffZoneModel } from '@libs/tariff-zone/tariff-zone.model';
import { TariffFareStageModel } from '@libs/tariff-fare-stage/tariff-fare-stage.model';
import { ReplayControlValueChanges } from '@libs/repeat-control-value-changes/replay-control-value-changes';
import { MonitoringValidatorSchemeColor } from '@services/monitoring/monitoring.model';
import { ValidatorStatusBo } from '@api/models/AnalyticCommon/Models/validator-status-bo';
import { StorageKeys } from '@core/storage/storage.keys';
import {
    ValidatorBoErrorStatus,
    ValidatorBoNetStatus,
    ValidatorSchemePointColorId,
    ValidatorSchemePointColorValue,
} from '@libs/monitoring/model/monitoring-validator-statuses.model';
import { intersection } from 'lodash-es';
import { Language } from '@api/models/UserService/Contract/language';


export const compareValidator =
    (controlNameToCompare: string): ValidatorFn =>
        (c: AbstractControl): ValidationErrors | null => {
            if (c.value === null || c.value.length === 0) {
                return null; // don't validate empty value
            }
            const controlToCompare = c.root.get(controlNameToCompare);
            if (controlToCompare) {
                const subscription: Subscription =
                    controlToCompare.valueChanges.subscribe(() => {
                        c.updateValueAndValidity();
                        subscription.unsubscribe();
                    });
            }
            return controlToCompare && controlToCompare.value !== c.value
                ? { compare: true }
                : null;
        };

export const dayOfMonthValidator =
    (controlNameToCompare: string, year: number): ValidatorFn =>
        (c: AbstractControl): ValidationErrors | null => {
            if (c.value === null || c.value.length === 0) {
                return null; // don't validate empty value
            }
            const controlToCompare = c.root.get(controlNameToCompare);
            if (controlToCompare) {
                const subscription: Subscription =
                    controlToCompare.valueChanges.subscribe(() => {
                        c.updateValueAndValidity();
                        subscription.unsubscribe();
                    });
            }

            const invalidObj: ValidationErrors = {
                pattern: true,
            };
            const day = +c.value;
            const isLeap = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

            if (!nullOrUndefined(controlToCompare.value)) {
                if (day < 1 || day > 31) {
                    return invalidObj;
                } else {
                    switch (controlToCompare.value) {
                        case 2:
                            if (day > (isLeap ? 29 : 28)) {
                                return invalidObj;
                            }
                            break;
                        case 4:
                        case 6:
                        case 9:
                        case 11:
                            if (day > 30) {
                                return invalidObj;
                            }
                    }
                }
            }

            return null;
        };

export const noLessValidator =
    (controlName: string): ValidatorFn =>
        (c: AbstractControl): ValidationErrors | null => {
            if (c.value === null || c.value.length === 0) {
                return null; // don't validate empty value
            }
            const controlEndRange = c.root.get(controlName);
            if (controlEndRange) {
                const subscription: Subscription = controlEndRange.valueChanges.subscribe(
                    () => {
                        c.updateValueAndValidity();
                        subscription.unsubscribe();
                    },
                );
            }
            return controlEndRange && +controlEndRange.value > +c.value
                ? { pattern: true }
                : null;
        };

export const equalValidator =
    (equalValue: string): ValidatorFn =>
        (c: AbstractControl): ValidationErrors | null => {
            if (c.value === null || c.value.length === 0) {
                return null; // don't validate empty value
            }
            return equalValue && equalValue === c.value ? { equal: true } : null;
        };

export const equalPhoneValidator =
    (controlPhoneCodeName: string, phoneEqualValue: string): ValidatorFn =>
        (c: AbstractControl): ValidationErrors | null => {
            if (c.value === null || c.value.length === 0) {
                return null; // don't validate empty value
            }
            const controlPhoneCode = c.root.get(controlPhoneCodeName);
            if (controlPhoneCode) {
                const subscription: Subscription =
                    controlPhoneCode.valueChanges.subscribe(() => {
                        c.updateValueAndValidity();
                        subscription.unsubscribe();
                    });
            }

            return phoneEqualValue &&
            phoneEqualValue === convertPhoneToDb(c.value, controlPhoneCode.value)
                ? { equal: true }
                : null;
        };

export const allLangRequiredValidator =
    (languages: Language[]): ValidatorFn =>
        (c: AbstractControl): ValidationErrors | null => {
            if (c.value === null || c.value.length === 0) {
                return null; // don't validate empty value
            }

            if (languages) {
                const check = languages.filter((lang) => {
                    const find = c.value.find(
                        (value) => value.lang.toLowerCase() === lang.code.toLowerCase(),
                    );
                    if (
                        find &&
                        find.value !== null &&
                        find.value !== '' &&
                        find.value !== undefined
                    ) {
                        return lang;
                    }
                });

                if (check && check.length !== languages.length) {
                    return {
                        required: true,
                    };
                }
            }
        };

export const notUndefined = (anyValue): boolean => typeof anyValue !== 'undefined';

export const columnSortNumber =
    (code: string, direction: SortDirection) =>
        (item: unknown, itemNext: unknown) => {
            if (item[code] > itemNext[code]) {
                return direction === 'ASC' ? 1 : -1;
            } else if (item[code] < itemNext[code]) {
                return direction === 'ASC' ? -1 : 1;
            } else {
                return 0;
            }
        };

export const nullOrUndefined = (value: unknown): boolean =>
    value === undefined || value === null;

export const notNullOrUndefined = (value: unknown): boolean =>
    value !== undefined && value !== null;

export const getTrmId = (organizations: OrganizationModel[]): number | null => {
    const storageKey = (
        'ngx-webstorage|' + StorageKeys.RefSelectedTrm
    ).toLowerCase();
    if (window && window.hasOwnProperty('sessionStorage')) {
        let userTrmId: number = window.sessionStorage.getItem(storageKey)
            ? +window.sessionStorage.getItem(storageKey)
            : null;
        const orgTrm: OrganizationModel = organizations.find((org: OrganizationModel) => notNullOrUndefined(org.trmId));
        if (nullOrUndefined(userTrmId) && orgTrm) {
            window.sessionStorage.setItem(storageKey, String(orgTrm.trmId));
            userTrmId = orgTrm.trmId;
        } else if (notNullOrUndefined(userTrmId)) {
            const existOrg: OrganizationModel = organizations.find((org: OrganizationModel) => org.trmId === userTrmId);
            if (!existOrg && orgTrm) {
                window.sessionStorage.setItem(storageKey, String(orgTrm.trmId));
                userTrmId = orgTrm.trmId;
                window.location.reload();
            }
        }
        return userTrmId;
    } else {
        return null;
    }
};

export const filterPlainArray = <T, T2>(array: T[], filters: T2): T[] => {
    if (!filters) {
        return array;
    }
    const filterKeys: string[] = Object.keys(filters);
    // @ts-ignore
    return array.filter((item: T) =>
        filterKeys.every((key: string) => {
            if (nullOrUndefined(filters[key]) || filters[key] === '') {
                return true;
            } else {
                /**
                 * Monitoring custom filter cases
                 */
                if (key === 'vlNumberStart') {
                    return new RegExp('^' + filters[key], 'i').test(item.number);
                }
                if (key === 'vlProblem') {
                    return filters[key]
                        ? item.validatorStatusNum === 2 || item.netStatus !== 2
                        : true;
                }

                return (
                    getFilterPlainValue(filters[key]) === getFilterPlainValue(item[key])
                );
            }
        }),
    );
};

export const getFilterPlainValue = (value: string): unknown =>
    typeof value === 'string' ? value.toUpperCase() : value;

export const convertPhoneToDb = (
    phoneValue: string,
    phoneCode: string,
): string => {
    return (phoneCode + phoneValue).padStart(phoneValue.length + 3, '0');
};

/**
 * Identity functions
 */

export const tariffZoneIdentity = (index: number, zone: TariffZoneModel) =>
    zone.zoneId;

export const tariffFareStageIdentity = (
    index: number,
    fareStage: TariffFareStageModel,
) => fareStage.fareStage;

export const srvIdIdentity = (index: number, item: any) => item.srvId;

export const trfIdIdentity = (index: number, item: any) => item.trfId;

export const minDateDependency = (
    // @ts-ignore
    self,
    controlMaster: AbstractControl,
    controlSlave: AbstractControl,
) => {
    if (
        !controlMaster ||
        !controlSlave ||
        !self.hasOwnProperty('minDependencyDate')
    ) {
        return false;
    }

    const dateControlChanges$ = new ReplayControlValueChanges(controlMaster);
    dateControlChanges$
        .subscribe((value: Date) => {
            if (value) {
                controlSlave.reset(
                    value?.getTime() > controlSlave?.value?.getTime()
                        ? null
                        : controlSlave?.value,
                );
            }
            self.minDependencyDate = value ? value : null;
        });
};

export const detectStatusValidatorColor = (
    validatorMsg: ValidatorStatusBo,
    excludeErrorIds?: number[] = [],
): ValidatorSchemePointColorId => {
    if (validatorMsg.netStatus === ValidatorBoNetStatus.Online && validatorMsg.validatorStatusNum !== ValidatorBoErrorStatus.Error) {
        return ValidatorSchemePointColorId.Green;
    }

    if (validatorMsg.netStatus === ValidatorBoNetStatus.Warning && validatorMsg.validatorStatusNum !== ValidatorBoErrorStatus.Error) {
        return ValidatorSchemePointColorId.Yellow;
    }

    if (validatorMsg.netStatus === ValidatorBoNetStatus.Offline) {
        return ValidatorSchemePointColorId.Red;
    }

    if (validatorMsg.validatorStatusIds?.length) {
        const intersectionWithExcludeErrors: number[] = intersection(validatorMsg.validatorStatusIds, excludeErrorIds);

        if (intersectionWithExcludeErrors?.length) {
            /** All errors excluded in filter */
            if (intersectionWithExcludeErrors.length >= validatorMsg.validatorStatusIds.length) {
                return ValidatorSchemePointColorId.Green;
            } else {
                return ValidatorSchemePointColorId.Red;
            }
        }

        return ValidatorSchemePointColorId.Red;
    }

    return ValidatorSchemePointColorId.Gray;
};

export const getColorByStatusArray = (
    arStatuses: number[],
): MonitoringValidatorSchemeColor => {
    const arRed: number[] = arStatuses.filter((item) => item === ValidatorSchemePointColorId.Red);
    const arYellow: number[] = arStatuses.filter((item) => item === ValidatorSchemePointColorId.Yellow);
    const arGreen: number[] = arStatuses.filter((item) => item === ValidatorSchemePointColorId.Green);

    if (arRed?.length) {
        return {
            color: ValidatorSchemePointColorValue.Red,
            amount: arRed.length + +arYellow?.length,
        };
    }

    if (arYellow?.length) {
        return {
            color: ValidatorSchemePointColorValue.Yellow,
            amount: arYellow.length,
        };
    }

    if (arGreen?.length) {
        return {
            color: ValidatorSchemePointColorValue.Green,
            amount: null,
        };
    }

    return {
        color: ValidatorSchemePointColorValue.Gray,
        amount: null,
    };
};

export const getColorByStatusSingle = (
    status: ValidatorSchemePointColorId,
): ValidatorSchemePointColorValue => {
    switch (status) {
        case ValidatorSchemePointColorId.Red:
            return ValidatorSchemePointColorValue.Red;
        case ValidatorSchemePointColorId.Green:
            return ValidatorSchemePointColorValue.Green;
        case ValidatorSchemePointColorId.Yellow:
            return ValidatorSchemePointColorValue.Yellow;
        default:
            return ValidatorSchemePointColorValue.Gray;
    }
};

export const stopPropagation = (event: Event) => {
    if (event) {
        event.stopPropagation();
    }
};
