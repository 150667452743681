import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IconModule } from '@ui/icon/icon.module';
import { TranslateModule } from '@ngx-translate/core';


/**
 * Print icon control
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-print-control',
    templateUrl: './print-control.component.html',
    styleUrls: ['./print-control.component.scss'],
    standalone: true,
    imports: [
        IconModule,
        TranslateModule,
    ],
})
export class PrintControlComponent {
    constructor(@Inject('window') private window: Window) {
    }

    public handlerPrint(): void {
        this.window.print();
    }
}
