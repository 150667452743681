import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecificValidityPeriodIntervalFormComponent } from './specific-validity-period-interval-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarControlModule } from '@ui/form/calendar-control/calendar-control.module';
import { ButtonModule } from '@ui/form/button/button.module';
import { ButtonRowModule } from '@ui/form/button-row/button-row.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
  declarations: [SpecificValidityPeriodIntervalFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CalendarControlModule,
    ButtonModule,
    ButtonRowModule,
    TimepickerModule,
  ],
  exports: [SpecificValidityPeriodIntervalFormComponent],
})
export class SpecificValidityPeriodIntervalFormModule {}
