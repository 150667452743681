<div class="form-group"
     [ngClass]="additionalGroupClass"
     [class.form-group-disabled]="selectControl?.status === 'DISABLED'">

    <label *ngIf="label" [for]="idAttrValue">
        {{ label }}
        <sup *ngIf="isRequiredField">*</sup>
    </label>

    <ng-select class="ng-select--form-control"
               [ngClass]="size ? ('ng-select--' + size) : null"
               [addTag]="addTag"
               [clearable]="clearable"
               [searchable]="searchable"
               [labelForId]="idAttrValue"
               [addTagText]="'global.actions.use' | translate"
               [clearAllText]="'global.labels.ng-select.clear' | translate"
               [notFoundText]="'global.labels.no_items_found' | translate"
               [items]="options"
               [placeholder]="placeholder"
               [readonly]="selectControl?.status === 'DISABLED'"
               [formControl]="selectControl"
               [class.ng-select--loading]="!options"
               [multiple]="multiple"
               [bindLabel]="bindLabel"
               [bindValue]="bindValue">

        <ng-template ng-label-tmp let-item="item" *ngIf="translateOptions">
            {{ item?.[bindLabel] | translate }}
        </ng-template>

        <ng-template ng-option-tmp let-item="item" *ngIf="translateOptions">
            {{ item?.[bindLabel] | translate }}
        </ng-template>

    </ng-select>

    <div class="invalid-feedback" *ngIf="selectControl?.errors && selectControl?.touched">
        <span class="invalid-feedback-box">{{ selectControl.errors | validationMessage }}</span>
    </div>
</div>



