import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MenuItemsModel } from '@libs/menu/menu.model';
import { Observable } from 'rxjs';
import { DatabaseTimeModel } from '@libs/settings/settings.model';
import { ActivatedRoute } from '@angular/router';
import { PrivsService } from '@libs/privs/privs.service';
import { DatabaseTimeService } from '@services/database-time/database-time.service';
import { UserState } from '@core/state/user.state';
import { LoginResponse } from '@api/models/UserService/Contract/login-response';
import { SubmenuModule } from '@ui/submenu/submenu.module';
import { AsyncPipe, NgIf } from '@angular/common';
import { ClockComponent } from '@ui/clock/clock.component';
import { ExportControlComponent } from '@ui/export-control/export-control.component';
import { PrintControlComponent } from '@ui/print-control/print-control.component';
import { Priv } from '@api/models/UserService/Contract/priv';


/**
 * Navbar
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: true,
    imports: [
        SubmenuModule,
        AsyncPipe,
        NgIf,
        ClockComponent,
        ExportControlComponent,
        PrintControlComponent,
    ],
})
export class NavbarComponent {
    readonly #userState: UserState = inject(UserState);
    readonly #databaseTimeService: DatabaseTimeService = inject(DatabaseTimeService);
    readonly #privsService: PrivsService = inject(PrivsService);
    readonly #route: ActivatedRoute = inject(ActivatedRoute);

    /**
     * List menu items second level
     */
    public readonly items: MenuItemsModel[] = this.#route.snapshot.data.subMenuItems || [];
    /**
     * List user privileges
     */
    public readonly privs$: Observable<Priv[]> = this.#privsService.privs;
    /**
     * Database time
     */
    public readonly time$: Observable<DatabaseTimeModel> = this.#databaseTimeService.databaseTime;
    /**
     * User data
     */
    public readonly user$: Observable<LoginResponse> = this.#userState.user$;
}
