import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserState } from '@core/state/user.state';
import { Priv } from '@api/models/UserService/Contract/priv';
import { LoginResponse } from '@api/models/UserService/Contract/login-response';

/**
 * System user privilege service
 */
@Injectable({
    providedIn: 'root',
})
export class PrivsService {
    readonly #userState: UserState = inject(UserState);

    private readonly privs$: Observable<Priv[]> =
        this.#userState.user$.pipe(
            filter(Boolean),
            map((user: LoginResponse) => user.privs),
        );

    /**
     * Returns a list of privileges of the current system user
     */
    get privs(): Observable<Priv[]> {
        return this.privs$;
    }
}
