<div class="container-fluid d-print-none">
    <div class="row">
        <div class="col px-md-8">
            <div class="nav-bar d-flex flex-row align-items-center justify-content-between">
                <ui-submenu class="py-1"
                            [menuItems]="items"
                            [privs]="privs$ | async">

                </ui-submenu>
                <div class="tools-controls d-flex flex-row flex-nowrap align-items-center">
                    <ui-clock class="me-3"
                              *ngIf="user$ | async"
                              [time]="time$ | async">

                    </ui-clock>

                    <ui-export-control class="me-3"></ui-export-control>
                    <ui-print-control></ui-print-control>
                </div>
            </div>
        </div>
    </div>
</div>
