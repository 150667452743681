import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupDetailComponent } from './popup-detail/popup-detail.component';
import { PopupSortProductListComponent } from './popup-sort-product-list/popup-sort-product-list.component';
import {
    PopupAddSpecificPeriodIntervalComponent,
} from './popup-add-specific-period-interval/popup-add-specific-period-interval.component';
import { PopupEditStopComponent } from './popup-edit-stop/popup-edit-stop.component';
import { PopupAddStopToRouteComponent } from './popup-add-stop-to-route/popup-add-stop-to-route.component';
import { PopupSetRouteVersionComponent } from './popup-set-route-version/popup-set-route-version.component';
import { PopupEditZoneComponent } from './popup-edit-zone/popup-edit-zone.component';
import { PopupsModule } from '@popups/popups.module';
import {
    SpecificValidityPeriodIntervalFormModule,
} from '@ui/specific-validity-period-interval-form/specific-validity-period-interval-form.module';
import { FareMenuSortListModule } from '@ui/fare-menu-sort-list/fare-menu-sort-list.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ZoneFormModule } from '@containers/zone-form/zone-form.module';
import { StopMapModule } from '@ui/stop-map/stop-map.module';
import { StopByRouteFormModule } from '@ui/stop-by-route-form/stop-by-route-form.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CalendarControlModule } from '@ui/form/calendar-control/calendar-control.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@ui/form/button/button.module';
import { ButtonRowModule } from '@ui/form/button-row/button-row.module';
import { PreLoaderModule } from '@ui/pre-loader/pre-loader.module';
import { PopupSelectDesignElementComponent } from './popup-select-design-element/popup-select-design-element.component';
import { StopFormComponent } from '../features/page-routes/page-stops/stop-form/stop-form.component';
import { ViewDesignElementComponent } from '@ui/view-design-element/view-design-element.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { FilterByPropPipe } from '@pipes/filter-by-prop/filter-by-prop.pipe';
import { SafeHtmlPipe } from '@pipes/safe-html/safe-html.pipe';


@NgModule({
    declarations: [
        PopupDetailComponent,
        PopupSortProductListComponent,
        PopupAddSpecificPeriodIntervalComponent,
        PopupEditStopComponent,
        PopupAddStopToRouteComponent,
        PopupSetRouteVersionComponent,
        PopupEditZoneComponent,
        PopupSelectDesignElementComponent,
    ],
    imports: [
        CommonModule,
        PopupsModule,
        SpecificValidityPeriodIntervalFormModule,
        FareMenuSortListModule,
        MatButtonToggleModule,
        ZoneFormModule,
        StopMapModule,
        StopByRouteFormModule,
        TimepickerModule,
        CalendarControlModule,
        TranslateModule,
        ReactiveFormsModule,
        ButtonModule,
        ButtonRowModule,
        PreLoaderModule,
        StopFormComponent,
        ViewDesignElementComponent,
        NgxMasonryModule,
        FilterByPropPipe,
        SafeHtmlPipe,
    ],
})
export class PopupContainersModule {
}
