<ng-container *ngIf="languages$ | async as languages; else preloader">
    <ng-container *ngIf="zone$ | async as zone; else preloader">
        <ui-zone-form [srvId]="srvId"
                      [zoneId]="zoneId"
                      [zone]="zone"
                      [languages]="languages"
                      (cancel)="handlerCancel()"
                      (edit)="handlerEditZone($event)">

        </ui-zone-form>
    </ng-container>
</ng-container>

<ng-template #preloader>
    <ui-pre-loader></ui-pre-loader>
</ng-template>
