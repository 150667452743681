import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@popups/dialog/dialog.tokens';
import { TariffZoneBody, TariffZoneModel, TariffZoneModelId } from '@libs/tariff-zone/tariff-zone.model';
import { TariffZoneService } from '@libs/tariff-zone/tariff-zone.service';
import { Observable } from 'rxjs';
import { PushService } from '@core/push/push.service';
import { PushCodeModel } from '@core/push/push-code.model';
import { LanguageState } from '@core/localization/language.state';
import { Language } from '@api/models/Postgres/Model/language';


/**
 * Form zone
 */
@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ref-popup-edit-zone',
    templateUrl: './popup-edit-zone.component.html',
    styleUrls: ['./popup-edit-zone.component.scss'],
})
export class PopupEditZoneComponent {
    readonly #push: PushService = inject(PushService);
    readonly #languageState: LanguageState = inject(LanguageState);

    public readonly zoneId: TariffZoneModelId = +this.data.params?.serviceParams?.zoneId;
    public readonly srvId: number = +this.data.params?.serviceParams?.srvId;
    public readonly languages$: Observable<Language[]> = this.#languageState.languages$;
    public zone$: Observable<TariffZoneModel> = this.tariffZoneService.getById(
        this.zoneId,
    );

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private dialogRef: MatDialogRef<PopupEditZoneComponent>,
        private tariffZoneService: TariffZoneService,
    ) {
    }

    public handlerEditZone(body: TariffZoneBody): void {
        this.tariffZoneService
            .edit({
                ...body,
                ...{ zoneId: this.zoneId },
            })
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.#push.success(PushCodeModel.SuccessChange);
                this.dialogRef.close();
            });
    }

    public handlerCancel(): void {
        this.dialogRef.close();
    }
}
