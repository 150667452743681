<ng-container *ngIf="languages$ | async as languages; else preloader">
    <ng-container *ngIf="stop$ | async as stop; else preloader">
        <ref-stop-form [organizations]="organizations$ | async"
                       [patchCoordinates]="patchCoord$ | async"
                       [stop]="stop"
                       [languages]="languages"
                       (cancel)="handlerCancel()"
                       (edit)="handlerEditStop($event)">

        </ref-stop-form>

        <ui-stop-map class="d-block mt-6"
                     [stop]="stop"
                     [zoom]="zoom"
                     [center]="{ lat: stop.lat, lng: stop.lon }"
                     (outputCoord)="patchCoord($event)">

        </ui-stop-map>
    </ng-container>
</ng-container>

<ng-template #preloader>
    <ui-pre-loader></ui-pre-loader>
</ng-template>
