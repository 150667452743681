import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TariffSpecificValidityPeriodIntervalBody } from '@libs/specific-validity-period/specific-validity-period.model';
import { TimeFormatModel } from '@libs/time-format/time-format.model';
import { TimeFormatService } from '@libs/time-format/time-format.service';
import { LocalDateAdapter } from '@services/local-date-adapter/local-date-adapter';

/**
 * Form for adding an interval of a special period of validity
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-specific-validity-period-interval-form',
  templateUrl: './specific-validity-period-interval-form.component.html',
  styleUrls: ['./specific-validity-period-interval-form.component.scss'],
})
export class SpecificValidityPeriodIntervalFormComponent {
  /**
   * Read-only mode flag
   */
  @Input() lyView: boolean;
  /**
   * Server pending flag
   */
  @Input() pending: boolean;
  /**
   * Submit form event
   *
   * @returns Form data
   */
  @Output() submitForm: EventEmitter<TariffSpecificValidityPeriodIntervalBody> =
    new EventEmitter<TariffSpecificValidityPeriodIntervalBody>();

  readonly timeFormat: TimeFormatModel = this.timeFormatService.format;
  readonly defaultTimeValue: Date = new Date(new Date().setHours(0, 0, 0));

  readonly form: FormGroup = this.fb.group({
    ttpId: this.fb.control(null, [Validators.required]),
    datePeriod: this.fb.array([
      this.fb.group({
        dateFrom: this.fb.control(null, [Validators.required]),
        timeFrom: this.fb.control(this.defaultTimeValue, [Validators.required]),
        dateTo: this.fb.control(null, [Validators.required]),
        timeTo: this.fb.control(this.defaultTimeValue, [Validators.required]),
        sellDateFrom: this.fb.control(null, [Validators.required]),
        sellTimeFrom: this.fb.control(this.defaultTimeValue, [
          Validators.required,
        ]),
        sellDateTo: this.fb.control(null, [Validators.required]),
        sellTimeTo: this.fb.control(this.defaultTimeValue, [
          Validators.required,
        ]),
      }),
    ]),
  });

  constructor(
    private fb: FormBuilder,
    private timeFormatService: TimeFormatService,
    private dateFormatService: LocalDateAdapter,
  ) {}

  get dateControls() {
    return this.form.get('datePeriod') as FormArray;
  }

  /**
   * Period id
   */
  @Input() set ttpId(ttpId: number) {
    this.form.patchValue(
      {
        ttpId,
      },
      {
        emitEvent: false,
      },
    );
  }

  handlerAdd(
    controls: FormArray,
    index: number,
    dateFrom?: string,
    dateTo?: string,
    timeFrom?: string,
    timeTo?: string,
    sellDateFrom?: string,
    sellDateTo?: string,
    sellTimeFrom?: string,
    sellTimeTo?: string,
    disabled?: boolean,
  ): void {
    const control = this.fb.group({
      dateFrom: this.fb.control(
        { value: dateFrom ? new Date(dateFrom) : null, disabled },
        [Validators.required],
      ),
      dateTo: this.fb.control(dateTo ? new Date(dateTo) : null, [
        Validators.required,
      ]),
      timeFrom: this.fb.control(
        { value: timeFrom ? timeFrom : this.defaultTimeValue, disabled },
        [Validators.required],
      ),
      timeTo: this.fb.control(
        { value: timeTo ? timeTo : this.defaultTimeValue, disabled },
        [Validators.required],
      ),
      sellDateFrom: this.fb.control(
        { value: sellDateFrom ? new Date(sellDateFrom) : null, disabled },
        [Validators.required],
      ),
      sellDateTo: this.fb.control(sellDateTo ? new Date(sellDateTo) : null, [
        Validators.required,
      ]),
      sellTimeFrom: this.fb.control(
        {
          value: sellTimeFrom ? sellTimeFrom : this.defaultTimeValue,
          disabled,
        },
        [Validators.required],
      ),
      sellTimeTo: this.fb.control(
        { value: sellTimeTo ? sellTimeTo : this.defaultTimeValue, disabled },
        [Validators.required],
      ),
    });

    if (controls) {
      controls.insert(index, control);
    }
  }

  handlerRemove(index: number): void {
    this.dateControls.controls.splice(index, 1);
    if (this.ttpId) {
    } else {
      this.form.markAsDirty();
      this.dateControls.updateValueAndValidity();
    }
  }

  handlerSubmit(): void {
    const { ttpId, datePeriod } = this.form.getRawValue();

    const dateFromArr: string[] = [];
    const dateToArr: string[] = [];
    const sellFromArr: string[] = [];
    const sellToArr: string[] = [];

    if (datePeriod instanceof Array) {
      datePeriod.forEach(
        (value: {
          dateFrom: Date;
          dateTo: Date;
          timeFrom: Date;
          timeTo: Date;
          sellDateFrom: Date;
          sellDateTo: Date;
          sellTimeFrom: Date;
          sellTimeTo: Date;
        }) => {
          dateFromArr.push(
            this.dateFormatService.prepareDateTimeForDB(
              value.dateFrom,
              value.timeFrom,
            ),
          );
          dateToArr.push(
            this.dateFormatService.prepareDateTimeForDB(
              value.dateTo,
              value.timeTo,
            ),
          );
          sellFromArr.push(
            this.dateFormatService.prepareDateTimeForDB(
              value.sellDateFrom,
              value.sellTimeFrom,
            ),
          );
          sellToArr.push(
            this.dateFormatService.prepareDateTimeForDB(
              value.sellDateTo,
              value.sellTimeTo,
            ),
          );
        },
      );
    }

    this.submitForm.emit({
      ttpId,
      dateFromArr,
      dateToArr,
      sellFromArr,
      sellToArr,
    });
  }
}
