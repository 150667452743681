<div
    class="form-group position-relative mb-0"
    [class.form-group-disabled]="disabled"
    [ngClass]="{ 'd-flex flex-row align-items-center': horizontal }">
    <label
        class="d-block"
        [attr.for]="identifier"
        *ngIf="label"
        [class.me-3]="horizontal"
        [class.mb-0]="horizontal"
        [class.mb-1]="!horizontal">{{ label | translate }}<sup *ngIf="isRequiredField">*</sup>
    </label>

    <div class="input-group">
        <input [ngClass]="size ? ('form-control-' + size) : null"
               [attr.id]="identifier"
               class="form-control"
               #calendar="bsDatepicker"
               [bsValue]="control?.value"
               [disabled]="disabled ? true : null"
               [minDate]="minDate"
               [maxDate]="maxDate"
               (bsValueChange)="onCalendarChange($event)"
               (keyup)="onKeyUp($event)"
               type="text"
               placeholder=""
               bsDatepicker
        />

        <button class="btn btn-outline calendar"
                [disabled]="disabled"
                (click)="calendar.toggle()"
                type="button">

        </button>
    </div>
</div>
