import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { TariffVersionModel, TariffVersionModelDate } from '../tariff-versions/tariff-version.model';
import {
    TariffCap,
    TariffCapBody,
    TariffDomain,
    TariffDomainBody,
    TariffPass,
    TariffPassBody,
    TariffTicket,
} from './models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConfigService } from '../api-config/api-config.service';
import { catchError, finalize, map, publishReplay, refCount, repeatWhen, switchMap } from 'rxjs/operators';
import { TariffDetailModel, TariffSection } from './models/tariff-detail.model';
import { OrganizationService } from '@libs/organization/organization.service';
import { getTrmId } from '@libs/functions';
import { TariffDomainControlModel } from '@libs/domain/domain.model';

/**
 * Tariff Service
 */
@Injectable({
    providedIn: 'root',
})
export class TariffService {
    private readonly version = 'Tariff';
    private pending$: Subject<boolean> = new Subject();
    private updatePasses$: Subject<any> = new Subject<any>();
    private updateTickets$: Subject<any> = new Subject<any>();
    private updateDomains$: Subject<any> = new Subject<any>();
    private updateCaps$: Subject<any> = new Subject<any>();

    private readonly requestForDomainsControlList =
        this.organizationService.organizations.pipe(
            map(getTrmId),
            switchMap((trmId: number) =>
                this.http.get<TariffDomainControlModel[]>(
                    this.apiConfigService.getMethodUrl(
                        'boservice.tariff.gettariffsdomain',
                    ),
                    {
                        params: {
                            trmId,
                        },
                    },
                ),
            ),
            publishReplay(1),
            refCount(),
        );

    constructor(
        private http: HttpClient,
        private apiConfigService: ApiConfigService,
        private organizationService: OrganizationService,
    ) {
    }

    get pending(): Observable<boolean> {
        return this.pending$.asObservable();
    }

    /**
     * Returns a list of control areas
     */
    get domainsControl(): Observable<TariffDomainControlModel[]> {
        return this.requestForDomainsControlList;
    }

    /**
     * Updates tariff entities from the server by code
     *
     * @param code - entity code (corresponds to a tab on the interface)
     */
    set update(code: TariffSection) {
        switch (code) {
            case 'pass':
                this.updatePasses$.next(null);
                break;
            case 'ticket':
                this.updateTickets$.next(null);
                break;
            case 'domain':
                this.updateDomains$.next(null);
                break;
            case 'cap':
                this.updateCaps$.next(null);
                break;
        }
    }

    /**
     * Returns the list of fares on the Passes tab
     *
     * @param dateFrom - tariff version date
     */
    getPasses(dateFrom: TariffVersionModelDate): Observable<TariffPass[]> {
        return this.organizationService.organizations.pipe(
            map(getTrmId),
            switchMap((trmId: number) =>
                this.http
                    .get<TariffPass[]>(this.apiUrl('tariffs/pass'), {
                        params: new HttpParams()
                            .append('dateFrom', dateFrom)
                            .append('trmId', String(trmId)),
                    })
                    .pipe(
                        repeatWhen(() => this.updatePasses$),
                        map((response) => response),
                    ),
            ),
        );
    }

    /**
     * Changes items on the Pass tab
     *
     * @param data - Array of TariffPass[] elements
     * @param version - Tariff version object
     */
    savePasses(
        data: TariffPass[],
        version: TariffVersionModel,
    ): Observable<void> {
        const active = data.filter((p) => p.isActive);

        if (!active.length) {
            return new Observable((observer) => {
                observer.next();
                observer.complete();
            });
        }

        const body = active.map(
            (pass) =>
                ({
                    dateFrom: version.dateFrom,
                    tariffId: pass.tariffId,
                    price: pass.price,
                    priority: pass.priority,
                } as TariffPassBody),
        );

        this.pending$.next(true);
        return this.organizationService.organizations.pipe(
            map(getTrmId),
            switchMap((trmId: number) =>
                this.http
                    .put<void>(this.apiUrl('tariffs/pass'), body, {
                        params: new HttpParams()
                            .append('trmId', String(trmId)),
                    })
                    .pipe(finalize(() => this.pending$.next(false))),
            ),
        );
    }

    /**
     * Returns the list of fares on the Tickets tab
     *
     * @param dateFrom - tariff version date
     */
    getTickets(dateFrom: TariffVersionModelDate): Observable<TariffTicket[]> {
        return this.organizationService.organizations.pipe(
            map(getTrmId),
            switchMap((trmId: number) =>
                this.http
                    .get<TariffTicket[]>(this.apiUrl('tariffs/ticket'), {
                        params: new HttpParams()
                            .append('dateFrom', dateFrom)
                            .append('trmId', String(trmId)),
                    })
                    .pipe(
                        repeatWhen(() => this.updateTickets$),
                        map((response) => response),
                    ),
            ),
        );
    }

    /**
     * Changes items on the Tickets tab
     *
     * @param data - Array of TariffTicket[] elements
     * @param version - Tariff version object
     */
    saveTickets(
        data: TariffTicket[],
        version: TariffVersionModel,
    ): Observable<void> {
        const active = data.filter((p) => p.isActive);

        if (!active.length) {
            return new Observable((observer) => {
                observer.next();
                observer.complete();
            });
        }

        const body = active.map(
            (pass) =>
                ({
                    dateFrom: version.dateFrom,
                    tariffId: pass.tariffId,
                    price: pass.price,
                    priority: null,
                } as TariffPassBody),
        );

        this.pending$.next(true);

        return this.organizationService.organizations.pipe(
            map(getTrmId),
            switchMap((trmId: number) =>
                this.http
                    .put<void>(this.apiUrl('tariffs/ticket'), body, {
                        params: new HttpParams()
                            .append('trmId', String(trmId)),
                    })
                    .pipe(finalize(() => this.pending$.next(false))),
            ),
        );
    }

    /**
     * Deactivate items on tabs
     *
     * @param data - Array of TariffTicket[] elements | TariffCap[] | TariffPass[]
     * @param version - tariff version object
     * @param entity - 'cap' tab code | 'pass' | 'ticket'
     */
    disable(
        data: (TariffTicket | TariffCap | TariffPass)[],
        version: TariffVersionModel,
        entity: 'cap' | 'pass' | 'ticket',
    ): Observable<void> {
        const inactive = data.filter((p) => !p.isActive);

        if (!inactive.length) {
            return new Observable((observer) => {
                observer.next();
                observer.complete();
            });
        }

        const tariffIds =
            entity === 'cap'
                ? inactive.map((p: TariffTicket & TariffPass & TariffCap) => p.capId)
                : inactive.map(
                    (p: TariffTicket & TariffPass & TariffCap) => p.tariffId,
                );
        this.pending$.next(true);

        return this.organizationService.organizations.pipe(
            map(getTrmId),
            switchMap((trmId: number) =>
                this.http
                    .get<void>(this.apiUrl('tariffs/' + entity + '/disable'), {
                        params: new HttpParams({
                            fromObject: {
                                tariffIds: tariffIds.map((id) => id.toString()),
                            },
                        })
                            .append('trmId', String(trmId))
                            .append('dateFrom', version.dateFrom.toString()),
                    })
                    .pipe(finalize(() => this.pending$.next(false))),
            ),
        );
    }

    /**
     * Returns the list of rates on the Caps tab
     *
     * @param dateFrom - tariff version date
     */
    getCaps(dateFrom: TariffVersionModelDate): Observable<TariffCap[]> {
        return this.organizationService.organizations.pipe(
            map(getTrmId),
            switchMap((trmId: number) =>
                this.http
                    .get<TariffCap[]>(this.apiUrl('tariffs/cap'), {
                        params: new HttpParams()
                            .append('dateFrom', dateFrom)
                            .append('trmId', String(trmId)),
                    })
                    .pipe(repeatWhen(() => this.updateCaps$)),
            ),
        );
    }

    /**
     * Changes items on the Caps tab
     *
     * @param data - Array of TariffCap[] elements
     * @param version - Tariff version object
     */
    saveCaps(data: TariffCap[], version: TariffVersionModel): Observable<void> {
        const active = data.filter((p) => p.isActive);

        if (!active.length) {
            return new Observable((observer) => {
                observer.next();
                observer.complete();
            });
        }

        const body = active.map(
            (cap) =>
                ({
                    dateFrom: version.dateFrom,
                    capId: cap.capId,
                    capLimit: cap.capLimit,
                } as TariffCapBody),
        );

        this.pending$.next(true);

        return this.organizationService.organizations.pipe(
            map(getTrmId),
            switchMap((trmId: number) =>
                this.http
                    .put<void>(this.apiUrl('tariffs/cap'), body, {
                        params: new HttpParams()
                            .append('trmId', String(trmId)),
                    })
                    .pipe(finalize(() => this.pending$.next(false))),
            ),
        );
    }

    /**
     * Returns a list of tariffs on the Domains tab
     *
     * @param dateFrom - tariff version date
     */
    getDomains(dateFrom: TariffVersionModelDate): Observable<TariffDomain[]> {
        return this.organizationService.organizations.pipe(
            map(getTrmId),
            switchMap((trmId: number) =>
                this.http
                    .get<TariffDomain[]>(this.apiUrl('tariffs/domainThreshold'), {
                        params: new HttpParams()
                            .append('dateFrom', dateFrom)
                            .append('trmId', String(trmId)),
                    })
                    .pipe(repeatWhen(() => this.updateDomains$)),
            ),
        );
    }

    /**
     * Changes items on the Domains tab
     *
     * @param data - Array of TariffDomain[] elements
     * @param version - Tariff version object
     */
    saveDomains(
        data: TariffDomain[],
        version: TariffVersionModel,
    ): Observable<void> {
        const body = data.map(
            (domain) =>
                ({
                    dateFrom: version.dateFrom,
                    domainId: domain.domainId,
                    minBal: domain.minBal,
                    minBalPriv: domain.minBalPriv,
                    thresholdNum: domain.thresholdNum,
                } as TariffDomainBody),
        );

        this.pending$.next(true);
        return this.organizationService.organizations.pipe(
            map(getTrmId),
            switchMap((trmId: number) =>
                this.http
                    .put<void>(this.apiUrl('tariffs/domain'), body, {
                        params: new HttpParams()
                            .append('trmId', String(trmId)),
                    })
                    .pipe(finalize(() => this.pending$.next(false))),
            ),
        );
    }

    /**
     * Get tab item details
     *
     * @param trfId - tariff ID
     * @param date - Tariff version date
     * @param type - 'pass' tab code | 'ticket'
     */
    getById<T>(
        trfId: number,
        date: TariffVersionModelDate,
        type: string,
    ): Observable<T> {
        let method = '';
        switch (type) {
            case 'ticket':
                method = 'boservice.tariff.getfareticketdata';
                break;
            case 'pass':
                method = 'boservice.tariff.getfarepassdata';
                break;
        }
        return this.organizationService.organizations.pipe(
            map(getTrmId),
            switchMap((trmId: number) =>
                this.http
                    .post<TariffDetailModel>(
                        this.apiConfigService.getMethodUrl(method),
                        {
                            trfId,
                            date,
                        },
                        {
                            params: {
                                trmId,
                            },
                        },
                    )
                    .pipe(catchError((error) => of(null))),
            ),
        );
    }

    private apiUrl(url: string): string {
        return this.apiConfigService.getUrl([url], this.version);
    }
}
