import { inject, Injectable } from '@angular/core';
import { DEFAULT_LANG, TIME_FORMAT } from '@libs/constants';
import { TimeFormatModel } from '@libs/time-format/time-format.model';
import * as moment from 'moment';
import { AvailableLanguage } from '@core/localization/available-language';
import { LanguageState } from '@core/localization/language.state';

/**
 * Time format service
 */
@Injectable({
    providedIn: 'root',
})
export class TimeFormatService {
    readonly #languageState: LanguageState = inject(LanguageState);
    readonly #meridianLangArray: AvailableLanguage[] = [AvailableLanguage.EnUs];

    get format(): TimeFormatModel {
        return {
            momentJsFormat: TIME_FORMAT.hasOwnProperty(
                this.#languageState.currentLang,
            )
                ? // @ts-ignore
                TIME_FORMAT[this.#languageState.currentLang]
                : TIME_FORMAT[DEFAULT_LANG],
            showMeridian:
                this.#meridianLangArray.indexOf(
                    this.#languageState.currentLang,
                ) > -1,
        };
    }

    static convertTimeToSeconds(date: Date): number {
        return moment
            .duration(date.getHours() + ':' + date.getMinutes())
            .asSeconds();
    }
}
