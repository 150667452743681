import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ThirdLevelMenuFactory } from '@libs/menu/third-level-menu.service';
import { thirdLevelMenu } from './sub.menu';
import { GOOGLE_MAPS_API_KEY } from '@libs/constants';
import { AgmCoreModule } from '@agm/core';
import { CommonAppComponent } from '@ui/common-app/common-app.component';
import { CommonAppModule } from '@ui/common-app/common-app.module';
import { PopupContainersModule } from './popup-containers/popup-containers.module';
import { PlacesState } from './shared/state/places.state';
import { ProvidersState } from './shared/state/providers.state';


export const loadSubMenu =
    (thirdLevelMenuFactory: ThirdLevelMenuFactory) => () =>
        thirdLevelMenuFactory.loadMenu(thirdLevelMenu);

@NgModule({
    imports: [
        CommonAppModule,
        AppRoutingModule,
        AgmCoreModule.forRoot({
            apiKey: GOOGLE_MAPS_API_KEY,
            libraries: ['drawing'],
        }),
        PopupContainersModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: loadSubMenu,
            deps: [ThirdLevelMenuFactory],
            multi: true,
        },
        PlacesState,
        ProvidersState,
    ],
    bootstrap: [CommonAppComponent],
})
export class AppModule {
}
